import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "dialog", "frame"]

    openDialog() {
        this.dialogTarget.showModal()
    }

    closeDialog() {
        this.dialogTarget.close()
    }

    animationBell(e) {
        e.target.querySelector("img").classList.toggle("ring-animation")
    }
}
