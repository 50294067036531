import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        dataLayer: Object,
        context: String,
    }

    routesDefinition = {
        "app-home-index": "home",
    }

    connect() {
        this.dataLayer = window.dataLayer || []
    }

    pageload(event) {
        const parsedContext = JSON.parse(this.contextValue)
        const pageDisplay = {
            event: "page.display",
            event_name: "page.display",
            page_type: this.routesDefinition[parsedContext.route],
            page_subject: this.routesDefinition[parsedContext.route],
            user_logger_in: !!parsedContext.user,
            user_category: "Candidat",
            partner_id: parsedContext.platform.intranetCode,
            partner_label: parsedContext.platform.label,
            ...event.params.pageload,
        }
        this.pushToGtm(pageDisplay)
    }

    menuTrigger(event) {
        const genericMenuEvent = {
            event: "generic",
            event_name: "menu_click",
            wording: event.params.menuTrigger?.wording ?? event.target.innerText,
            ...event.params.menuTrigger,
        }
        this.pushToGtm(genericMenuEvent)
    }

    ctaTrigger(event) {
        const genericCtaEvent = {
            event: "generic",
            event_name: "cta_click",
            wording: event.params.ctaTrigger?.wording ?? event.target.innerText,
            ...event.params.ctaTrigger,
        }
        this.pushToGtm(genericCtaEvent)
    }

    genericTrigger(event) {
        const genericEvent = {
            event: "generic",
            ...event.params.genericTrigger,
        }
        this.pushToGtm(genericEvent)
    }

    pushToGtm(data) {
        this.dataLayer.push(data)
    }
}
