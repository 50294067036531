import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "villesCollectionContainer",
        "regionsCollectionContainer",
        "departementsCollectionContainer",
        "perimetersArea",
        "input",
    ]

    static values = {
        prototypeVilles: String,
        prototypeRegions: String,
        prototypeDepartements: String,
    }

    clearCollectionContainer(container) {
        while (container.firstChild) {
            container.removeChild(container.firstChild)
        }
    }

    connect() {
        this.checkPerimetersShow()
    }

    resetHidden() {
        this.clearCollectionContainer(this.villesCollectionContainerTarget)
        this.clearCollectionContainer(this.regionsCollectionContainerTarget)
        this.clearCollectionContainer(this.departementsCollectionContainerTarget)
    }

    checkPerimetersShow() {
        if (this.villesCollectionContainerTarget.children.length > 0) {
            this.perimetersAreaTarget.classList.remove("hidden")
        } else {
            this.perimetersAreaTarget.classList.add("hidden")
            this.perimetersAreaTarget.selectedIndex = 0
        }
    }

    fillSuggestion(e) {
        const item = document.createElement("div")
        let container = null

        if (e.detail.type.startsWith("villes")) {
            item.innerHTML = this.prototypeVillesValue.replace(/__name__/g, "")
            container = this.villesCollectionContainerTarget
        }

        if (e.detail.type.startsWith("regions")) {
            item.innerHTML = this.prototypeRegionsValue.replace(/__name__/g, "")
            container = this.regionsCollectionContainerTarget
        }

        if (e.detail.type.startsWith("departements")) {
            item.innerHTML = this.prototypeDepartementsValue.replace(/__name__/g, "")
            container = this.departementsCollectionContainerTarget
        }

        const input = item.querySelector("input")

        if (container && input) {
            input.value = e.detail.id
            container.appendChild(item)
            this.inputTarget.value = e.detail.label
        }

        this.checkPerimetersShow()
    }
}
