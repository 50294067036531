import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        link: String,
    }

    navigateToOffer(event) {
        // event.button === 1 means wheel click
        if (event.type === "auxclick" && event.button !== 1) {
            return
        }

        if (event.button === 1 || event.ctrlKey || event.metaKey) {
            window.open(this.linkValue, "_blank")
            return
        }

        window.location.href = this.linkValue
    }
}
