import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["alertBtn", "alertBtnFixed"]

    fixAlertNotificationBell() {
        const alertBtnPosition = this.alertBtnTarget.getBoundingClientRect()

        if (alertBtnPosition.bottom < 0) {
            this.alertBtnFixedTarget.classList.remove("hidden")
        } else {
            this.alertBtnFixedTarget.classList.add("hidden")
        }
    }
}
