import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["dialog", "form", "publicationDate"]
    static classes = ["modale"]

    toggleFacetsDialog() {
        document.body.classList.toggle("overflow-hidden")
        for (const classe of this.modaleClasses) {
            this.dialogTarget.classList.toggle(classe)
        }
    }

    refreshFilters() {
        document.querySelector(".searchbar-form").submit()
    }

    removeFilter(event) {
        document.querySelector(".searchbar-form").submit()
        this.formTarget.submit()
    }
}
